<template>
    <modal ref="modalPedidosRecurrentes" titulo="Pedidos recurrentes" no-aceptar adicional="Aplicar" @adicional="postNotificacion">
        <div class="row mx-0">
            <div class="col">
                <p class="text-general f-500">
                    Selecciona los días del mes que desee el recordatorio a través de una notificación
                </p>
            </div>
        </div>
        <div class="row mx-0 justify-center mt-4">
            <div class="col box-calendar">
                <div class="row mx-0">
                    <button v-for="(day, index) in 31" :key="index" type="button" :class="`number${isActive(day) ? '-active' : ' '} cr-pointer d-middle-center text-general f-500 mb-2 mr-2`" @click="addDay(day)">
                        {{ day }}
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Service from '~/services/pedidos/pedidosRecurrentes'

export default {
    data(){
        return{
            idRecurrente: 553,
            days: [],
            daysSelected: [],
        }
    },
    methods:  {
        toggle(idRecurrente){
            this.idRecurrente = idRecurrente;
            this.getNotificaciones();
            this.$refs.modalPedidosRecurrentes.toggle();
        },

        async getNotificaciones(){
            try {
                const {data} = await Service.getNotificaciones(this.idRecurrente);
                this.days = data.dias;
            } catch(e){
                this.errorCatch(e)
            }
        },

        addDay(day){
            if(!_.includes(this.daysSelected, day) && !_.includes(this.days, day)){
                return this.daysSelected.push(day);
            }
            this.removeDay(day);
        },

        removeDay(day){
            let index = this.daysSelected.findIndex((item) => parseInt(item) === parseInt(day));
            if(index != -1){
                this.daysSelected.splice(index, 1);
            }

            let inDataBase = this.days.findIndex((item) => parseInt(item) === parseInt(day));
            if(inDataBase != -1){
                this.deleteNotificacion(day, index = inDataBase);
            }
        },

        async postNotificacion(){
            try {
                if(this.daysSelected.length < 1)return this.notificacion('Alerta', 'Usted no ha seleccionado nuevos días para recordatorio', 'warning');

                const model = {
                    idRecurrente: this.idRecurrente,
                    days: this.daysSelected,
                };

                const {data} = await Service.postNotificacionRecurrente(model)
                this.notificacion('Mensaje', data.mensaje, data.tipo)
                this.clear();
                this.$refs.modalPedidosRecurrentes.toggle();
            } catch(e){
                this.errorCatch(e)
            }
        },

        async deleteNotificacion(day, index){
            try {
                const {data} = await Service.deleteNotificacionRecurrente(this.idRecurrente, day);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.days.splice(index, 1);
            } catch(e){
                this.errorCatch(e)
            }
        },

        isActive(day){
            if(_.includes(this.daysSelected, day) || _.includes(this.days, day)){
                return true;
            }
            return false;
        },

        clear(){
            this.idRecurrente = null;
            this.days = [];
            this.daysSelected = [];
        },
    }
}
</script>
<style lang="scss" scoped>
.card-pedido{
    min-height: 100px;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 4px #00000014;
}

.box-calendar{
    width:365px;
    min-height: 221px;
    .number{
        width: 44px;
        height: 44px;
        border: none !important;
        background-color: transparent !important;
        border-radius: 50%;
        &-active{
            @extend .number;
            color: #FFFFFF !important;
            background-color: var(--color-general) !important;
        }
    }
}
</style>